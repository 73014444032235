import { createTheme, PaletteOptions, SimplePaletteColorOptions } from "@mui/material/styles"
import createBreakpoints from "@mui/system/createTheme/createBreakpoints"

declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    charts: Record<`${number}`, string>
  }
}

declare module "@mui/material/styles" {
  interface TypeBackground {
    secondary: string
    container: string
    containerLight: string
  }
}

const breakpoints = createBreakpoints({})

const palette: PaletteOptions = {
  primary: {
    main: "#191882",
    light: "#796DFF",
    dark: "#010148",
  },
  secondary: {
    main: "#D0D0F4",
    light: "#A199FF",
    dark: "#4332FF",
  },
  background: {
    default: "#fff",
    secondary: "#F5F5FF",
    container: "#D0D0F4",
    containerLight: "#E4E4FA",
  },
  text: {
    primary: "#000",
    secondary: "#767676",
    disabled: "#BDBDBD",
  },
  error: {
    main: "#D70000",
  },
  success: {
    main: "#5B9853",
  },
  grey: {
    "100": "#E0E0E0",
    "200": "#767676",
    "300": "#F2F2F2",
    "400": "#414141",
  },
  common: {
    charts: {
      "100": "#b4ebcc",
      "200": "#f8bf6e",
      "300": "#f08f73",
      "400": "#ef8567",
    },
  },
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1400,
    },
  },
  palette: palette,
  typography: {
    fontFamily: `"NNDagny", "Helvetica", sans-serif`,
    htmlFontSize: 16,
    fontWeightBold: "bold",
    h1: {
      fontFamily: '"Manrope", sans-serif',
      fontSize: "2rem",
      lineHeight: "2.5rem",
      fontWeight: 800,
      paddingBottom: "1.5rem",
      [breakpoints.up("md")]: {
        fontSize: "2.5rem",
        lineHeight: "2.75rem",
        paddingBottom: "2rem",
      },
    },
    h2: {
      fontFamily: '"Manrope", sans-serif',
      fontSize: "1.75rem",
      lineHeight: "2.25rem",
      fontWeight: 800,
      paddingBottom: "1.5rem",
      [breakpoints.up("md")]: {
        fontSize: "2rem",
        lineHeight: "2.5rem",
        paddingBottom: "2rem",
      },
    },
    h3: {
      fontFamily: '"Manrope", sans-serif',
      fontSize: "1.5rem",
      lineHeight: "1.75rem",
      fontWeight: 800,
      paddingBottom: "1.5rem",
      [breakpoints.up("md")]: {
        fontSize: "1.75rem",
        lineHeight: "2.25rem",
        paddingBottom: "2rem",
      },
    },
    h4: {
      fontFamily: '"Manrope", sans-serif',
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      fontWeight: 800,
      paddingBottom: "1.5rem",
      [breakpoints.up("md")]: {
        fontSize: "1.25rem",
        lineHeight: "1.75rem",
        paddingBottom: "2rem",
      },
    },
    h5: {
      fontFamily: '"Manrope", sans-serif',
      fontSize: "1rem",
      lineHeight: "1.25rem",
      fontWeight: 800,
      paddingBottom: "1rem",
      [breakpoints.up("md")]: {
        fontSize: "1.125rem",
        lineHeight: "1.25rem",
      },
    },
    h6: {
      fontFamily: '"Manrope", sans-serif',
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: 800,
      paddingBottom: "1rem",
      [breakpoints.up("md")]: {
        fontSize: "1rem",
        lineHeight: "1.125rem",
      },
    },
    body1: {
      fontSize: "1rem",
      fontWeight: "inherit",
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "inherit",
    },
    caption: {
      fontSize: "0.75rem",
      lineHeight: "1rem",
    },
    overline: {
      fontSize: "0.625rem",
      lineHeight: "0.75rem",
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        sizeMedium: {
          fontSize: "1rem",
          lineHeight: "1.375rem",
        },
        sizeSmall: {
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
        },
        sizeLarge: {
          fontSize: "1.125rem",
          lineHeight: "1.375rem",
        },
        root: {
          transition: "all 300ms",
          fontFamily: `"NNDagny", "Helvetica", sans-serif`,
          fontWeight: "bold",
          textTransform: "none",
        },
        text: {
          fontWeight: "bold",
        },
        outlined: {
          border: `1px solid ${(palette.primary as SimplePaletteColorOptions).main}`,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: "flex",
          "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, 2px) scale(0.75)",
          },
          "& .MuiOutlinedInput-notchedOutline span": {
            display: "none",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
        },
        root: {
          marginLeft: 0,
          paddingLeft: 0,
          gap: 4,
          position: "relative",
          lineHeight: 2,
          display: "flex",
          alignItems: "center",

          "&:before": {
            content: "''",
            backgroundImage:
              'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNiIgY3k9IjYiIHI9IjYiIGZpbGw9IiNCREJEQkQiLz4KPHBhdGggZD0iTTYuMDAwMTYgOEg2LjY2NjgzTTYuMDAwMTYgOEg1LjMzMzVNNi4wMDAxNiA4VjUuMzMzMzNINS4zMzM1TTYuMDAwMTYgNC4wMDMzM1Y0IiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==")',
            width: 12,
            height: 12,
            backgroundSize: "contain",
            backgroundPosition: "center",
          },
          "&.Mui-error": {
            "&:before": {
              backgroundImage:
                'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNiIgY3k9IjYiIHI9IjYiIGZpbGw9IiNENzAwMDAiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjYzNzAyIDQuNjY2NjdMNi42NjYzNCAzLjMzMzMzQzYuNjY2MzQgMy4xNjY2NyA2LjUwMDIyIDMgNi4zMzQwOSAzSDUuNjY5NkM1LjUwMzQ4IDMgNS4zMzczNSAzLjE2NjY3IDUuMzM3MzUgMy4zMzMzM0w1LjMzNzM1IDQuNjY2NjdDNS4zMzczNSA0Ljc1MjkzIDUuMzI3NTggNC45MTM3NCA1LjMzNzM1IDVMNS41MDM0OCA2LjMzMzMzQzUuNTEzMjUgNi40NzcxIDUuNjUwMDUgNi42NjY2NyA1Ljc5NjYzIDYuNjY2NjdINi4xNjc5N0M2LjMxNDU1IDYuNjY2NjcgNi40OTA0NSA2LjQ3NzEgNi41MDAyMiA2LjMzMzMzTDYuNjM3MDIgNUM2LjYzNzAyIDQuOTEzNzQgNi42MzcwMiA0Ljc1MjkzIDYuNjM3MDIgNC42NjY2N1oiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNi42NjYzNCA4LjMzMzMzQzYuNjY2MzQgOC43MDE1MiA2LjM2ODg0IDkgNi4wMDE4NCA5QzUuNjM0ODUgOSA1LjMzNzM1IDguNzAxNTIgNS4zMzczNSA4LjMzMzMzQzUuMzM3MzUgNy45NjUxNCA1LjYzNDg1IDcuNjY2NjcgNi4wMDE4NCA3LjY2NjY3QzYuMzY4ODQgNy42NjY2NyA2LjY2NjM0IDcuOTY1MTQgNi42NjYzNCA4LjMzMzMzWiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=")',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontWeight: 700,
          color: "#000",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root.Mui-disabled": {
            background: palette.grey[300],
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&$selected": {
            backgroundColor: "#E0E0E0",
            position: "relative",
            "&:after": {
              content: "''",
              position: "absolute",
              right: 16,
              top: "calc(50% - 9px)",
              width: 18,
              height: 18,
              background: `transparent url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgNS45MDkwOUw1LjM3NSAxMEwxNSAxIiBzdHJva2U9IiNGNDlEMjAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") no-repeat center / contain`,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          color: "#BDBDBD",
          "& svg": {
            opacity: ".5",
          },
          "&$checked": {
            "& svg": {
              opacity: 1,
              "&:first-child": {
                color: "#BDBDBD",
              },
              "&:last-child": {
                transform: "scale(.7)",
              },
            },
          },
        },
        colorSecondary: ({ theme }) => ({
          color: theme.palette.secondary?.dark,
          "&.Mui-checked": {
            color: theme.palette.secondary?.dark,
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.grey[100],
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        icon: ({ theme }) => ({
          color: theme.palette.secondary.dark,
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        // When on mobile, by default, the tooltip will only appear after a long click of 700ms,
        // by updating this prop, we can have the tooltip appear as soon as the user presses on it
        enterTouchDelay: 0,
      },
    },
  },
})

export default theme
