import { createContext, useEffect, useState } from "react"
import axios from "axios"

interface ConfigurationJson {
  loaded: boolean
  api_base_url: string
  user_pool_id: string
  user_pool_client_id: string
}

type Configuration = typeof initialProps

const initialProps = {
  loaded: !process.env.GATSBY_SYSTEM_PULLREQUEST_PULLREQUESTID,
  apiBaseUrl: process.env.GATSBY_SYSTEM_PULLREQUEST_PULLREQUESTID ? "" : process.env.GATSBY_REACT_APP_HTTP_API_ENDPOINT,
  userPoolId: process.env.GATSBY_SYSTEM_PULLREQUEST_PULLREQUESTID ? "" : process.env.GATSBY_AWS_COGNITO_USER_POOL,
  userPoolClientId: process.env.GATSBY_SYSTEM_PULLREQUEST_PULLREQUESTID
    ? ""
    : process.env.GATSBY_AWS_COGNITO_USER_POOL_CLIENT_ID,
}

export const ConfigurationContext = createContext<Configuration>(initialProps)

export const ConfigurationProvider: React.FC = ({ children }) => {
  const [configurationData, setConfigurationData] = useState<Configuration>(initialProps)

  useEffect(() => {
    if (!configurationData.loaded) {
      axios.get<ConfigurationJson>("/configuration.json").then(({ data }) =>
        setConfigurationData(configurationData => ({
          ...configurationData,
          loaded: true,
          apiBaseUrl: data.api_base_url,
          userPoolId: data.user_pool_id,
          userPoolClientId: data.user_pool_client_id,
        }))
      )
    }
  }, [])

  return <ConfigurationContext.Provider value={configurationData}>{children}</ConfigurationContext.Provider>
}
