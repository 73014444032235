import { GatsbySSR } from "gatsby"
import { AuthProvider, ConfigurationProvider, InsuranceDataProvider } from "./src/providers"
import "./src/styles.css"

export const onRouteUpdate = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: "optimize.activate" })

  setTimeout(() => {
    // wrap inside a timeout to ensure the title has properly been changed
    window.dataLayer.push({ event: "gatsby-route-change" })
  }, 50)
}

export const onInitialClientRender = () => {
  window.scrollTo(0, 0)
}

export const wrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }) => {
  return (
    <ConfigurationProvider>
      <AuthProvider>
        <InsuranceDataProvider>{element}</InsuranceDataProvider>
      </AuthProvider>
    </ConfigurationProvider>
  )
}
