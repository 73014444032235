export enum DATALAYER_EVENTS {
  TELECONSULTATION_SET_DATE = "teleconsultation-date",
  CALCULATOR_INTERACTION = "calc_interaction",
  CALCULATOR_COMPLETION = "calc_completion",
  QUIZ_INTERACTION = "quiz_interaction",
  QUIZ_COMPLETION = "quiz_completion",
  LOGIN = "login",
  SIGN_UP = "sign_up",
  SIGN_OUT = "sign_out",
  FUNNEL = "funnel",
  FUNNEL_ERROR = "funnel_error",
  BANNER_CLICK = "banner_click",
  BANNER_IMPRESSION = "banner_impression",
  PHONE_CALL_CENTER_CTA = "phone_call_center_cta",
  PHONE_CALL_CENTER_NAV = "phone_call_center_nav",
  PHONE_CALL_CENTER_FOOTER = "phone_call_center_footer",
  EMAIL_CALL_CENTER = "email_call_center",
  MAKE_APPOINTMENT = "make_appointment",
  EMAIL_NOT_WORKING = "email_not_working",
  CONTINUE_ONLINE = "continue_online",
  INFO_CONTINUE_ONLINE = "info_continue_online",
  SEND_SUPPORT_EMAIL = "send_support_email",
}

export enum FUNNEL_FORMS {
  REGISTER = "register",
  TELECONSULTATION = "teleconsultation",
  INSURANCE = "insurance",
}

export enum FUNNEL_STEPS {
  SPECIALIST = "specialist",
  DATE = "date",
  DETAILS = "details",
  HOME_DOCTOR = "home_doctor",
  SUCCESS = "success",
  SCHEDULE_CALL = "schedule_call",
  CODE_VERIFICATION = "code_verification",
  SEO_PACKAGES_OVERVIEW = "seo_packages_overview",
  OVERVIEW = "overview",
  PACKAGE = "package",
  MEMBER_OVERVIEW = "member_overview",
  EDIT_MAIN_MEMBER = "edit_main_member",
  EDIT_SUB_MEMBER = "edit_sub_member",
  ADD_MEMBER = "add_member",
  CALCULATE = "calculate",
  CONTINUE_ONLINE = "continue_online",
  OFFER = "offer",
  NO_OFFER = "no_offer",
}

export enum FUNNEL_ERRORS {
  USER_EXISTS = "user exists",
  USER_NAME_EXISTS = "user name exists",
  INVALID_CODE = "invalid code",
  CODE_EXPIRED = "code expired",
  CODE_COULD_NOT_BE_RESEND = "could not resend code",
  UNKNOWN_ERROR = "unknown error",
  VALIDATION = "validation",
  LOGIN_FAILED = "login failed",
  INVALID_ADDRESS = "invalid address",
  PESEL_IN_USE = "pesel in use",
}
