import { createContext, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { InsuranceMember, InsuranceQuestion, InsuranceQuestionAnswer } from "models"

interface TuzInsuranceQuestionsQuery {
  allInsuranceQuestion: {
    nodes: InsuranceQuestion[]
  }
}

export interface InsuranceDataState {
  packageName?: string
  setPackageName?: (packageName: string) => void
  members: InsuranceMember[]
  setMembers: (data: InsuranceMember[]) => void
  answers: InsuranceQuestionAnswer[]
  setAnswers: (data: InsuranceQuestionAnswer[]) => void
  offer?: number
  setOffer?: (data: number) => void
  questions: InsuranceQuestion[]
}

const initialState: InsuranceDataState = {
  packageName: null,
  setPackageName: () => void 0,
  members: [],
  setMembers: () => void 0,
  answers: [],
  setAnswers: () => void 0,
  offer: null,
  setOffer: () => void 0,
  questions: [],
}

export const InsuranceDataContext = createContext<InsuranceDataState>(initialState)

export const InsuranceDataProvider: React.FC = ({ children }) => {
  const questionsQueryData = useStaticQuery<TuzInsuranceQuestionsQuery>(query)
  const questions = questionsQueryData.allInsuranceQuestion.nodes
  const [packageName, setPackageName] = useState<InsuranceDataState["packageName"]>()
  const [members, setMembers] = useState<InsuranceDataState["members"]>([])
  const [answers, setAnswers] = useState<InsuranceDataState["answers"]>([])
  const [offer, setOffer] = useState<InsuranceDataState["offer"]>()

  return (
    <InsuranceDataContext.Provider
      value={{
        packageName,
        setPackageName,
        members,
        setMembers,
        answers,
        setAnswers,
        offer,
        setOffer,
        questions,
      }}
    >
      {children}
    </InsuranceDataContext.Provider>
  )
}

const query = graphql`
  {
    allInsuranceQuestion {
      nodes {
        question
        helpText
        expectedAnswer
        requireMembers
        options {
          label
          answer
        }
      }
    }
  }
`
