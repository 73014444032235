import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { GatsbySSR } from "gatsby"
import theme from "../../src/theme"

// NOTE: This needs to be named wrapRootElement for the gatsby to understand what component this is
export const wrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  )
}
