import { createContext, useEffect, useState } from "react"
import Amplify, { Hub, HubCapsule } from "@aws-amplify/core"
import { _window, pushDataEvent } from "@cep/core/utils"
import { DATALAYER_EVENTS } from "config/DatalayerEvents"
import { navigate } from "gatsby"
import { User, UserContextProps, UserState } from "models"
import { useConfiguration } from "hooks/useConfiguration"

export const UserContext = createContext<UserContextProps>(undefined)

export const AuthProvider: React.FC = ({ children }) => {
  const [userInfo, setUserInfo] = useState<User | undefined>()
  const [userState, setUserState] = useState<UserState>("no_account")
  const configuration = useConfiguration()

  const isLocalhost = window.location.hostname === "localhost"

  const domain = process.env.NODE_ENV !== "production" || isLocalhost ? "localhost" : "welbi.pl"
  const secure = process.env.NODE_ENV !== "production" || isLocalhost ? false : true

  useEffect(() => {
    Amplify.configure({
      cookieStorage: {
        domain,
        secure,
        path: "/",
        expires: 1, // 1 day
        sameSite: "lax",
        httpOnly: true,
      },
      mandatorySignIn: false,
      region: "eu-west-1",
      userPoolId: configuration.userPoolId,
      userPoolWebClientId: configuration.userPoolClientId,
    })
  }, [configuration])

  useEffect(() => {
    Hub.listen("auth", authListener)

    refreshSession()

    // Check if the creation is completed. If not navigate to the correct page
    const registerPath = "/konsultacja-lekarska-online/umow-wizyte/"
    const confirmationPath = "/konsultacja-lekarska-online/potwierdzenie-rejestracji/"
    const accountCreation = _window?.localStorage?.getItem("account-creation")
    //Remove the account creation indicator to prevent the user from being stuck in the creation process
    _window?.localStorage?.removeItem("account-creation")

    if (accountCreation === "homedoctor" && _window?.location?.pathname !== registerPath) {
      navigate(registerPath)
    } else if (accountCreation === "done" && _window?.location?.pathname !== confirmationPath) {
      navigate(confirmationPath)
    }

    return () => {
      Hub.remove("auth", authListener)
    }
  }, [])

  async function refreshSession(idToken?: any, accessToken?: any) {
    try {
      if (!idToken || !accessToken) {
        const { Auth } = await import("@aws-amplify/auth")
        const currentSession = await Auth.currentSession()
        idToken = currentSession.getIdToken()
        accessToken = currentSession.getAccessToken()
      }

      setUserInfo({
        sub: idToken.payload.sub,
        groups: idToken.payload["cognito:groups"],
        username: idToken.payload["cognito:username"],
        emailVerified: idToken.payload.email_verified,
        phoneNumberVerified: idToken.payload.phone_number_verified,
        phoneNumber: idToken.payload.phone_number,
        email: idToken.payload.email,
        name: idToken.payload.given_name,
        lastName: idToken.payload.family_name,
        consent: {
          privacy: idToken.payload["custom:cst_privacy"] === "1",
          marketingEmail: idToken.payload["custom:cst_marketing_email"] === "1",
          marketingPhone: idToken.payload["custom:cst_marketing_phone"] === "1",
          personalOffers: idToken.payload["custom:cst_personal_offers"] === "1",
        },
        accessToken: accessToken.getJwtToken(),
      })

      // Google analytics
      idToken?.payload?.sub && _window.dataLayer.push({ userId: idToken.payload.sub })

      if (idToken.payload["cognito:groups"]?.includes("HomeDoctor") === false) {
        setUserState("requires_home_doctor")
      } else {
        setUserState("completed")
      }
    } catch (e) {
      console.log("auth error:", e)
    }
  }

  function authListener(data: HubCapsule) {
    const idToken = data?.payload?.data?.signInUserSession?.idToken
    const accessToken = data?.payload?.data?.signInUserSession?.accessToken

    switch (data.payload.event) {
      case "signIn":
        refreshSession(idToken, accessToken)

        idToken.payload.sub && pushDataEvent(DATALAYER_EVENTS.LOGIN, { userId: idToken.payload.sub })
        break
      case "signUp":
        data.payload.data.userSub && pushDataEvent(DATALAYER_EVENTS.SIGN_UP, { userId: data.payload.data.userSub })
        if (data.payload.data?.userConfirmed === true) {
          setUserState("can_sign_in")
        }
        break
      case "signOut":
        setUserInfo(undefined)
        setUserState("no_account")
        pushDataEvent(DATALAYER_EVENTS.SIGN_OUT, { userId: null })
        break
      case "signIn_failure":
        if (data.payload.data?.code === "UserNotConfirmedException") {
          setUserState("requires_confirmation")
        }
        break
      case "configured":
        refreshSession(idToken, accessToken)

        break
      case "tokenRefresh":
        refreshSession(idToken, accessToken)
        break
    }
  }

  return <UserContext.Provider value={{ user: userInfo, state: userState }}>{children}</UserContext.Provider>
}
